const ACCESS_TOKEN = "access_token";
const USER_DATA = "user_data";
const SERVICES = "services";
const ROOM = "room";

const setAccessToken = (accessToken) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
};

const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

const setUserData = (userData) => {
  localStorage.setItem("selectedCountry", userData.user_detail.country);
  return localStorage.setItem(USER_DATA, JSON.stringify(userData));
};

const setJanusRoom = (userData) => {
  return localStorage.setItem(
    ROOM,
    userData.rooms.length ? userData.rooms[0].code : ""
  );
};

const setAllServices = (services) => {
  return localStorage.setItem(SERVICES, JSON.stringify(services));
};

const getUserData = () => {
  return JSON.parse(localStorage.getItem(USER_DATA));
};

const getServices = () => {
  return (
    localStorage.getItem(USER_DATA) &&
    JSON.parse(localStorage.getItem(USER_DATA)).services
  );
};

const getAllServices = () => {
  return (
    localStorage.getItem(SERVICES) && JSON.parse(localStorage.getItem(SERVICES))
  );
};

const clear = () => {
  localStorage.clear();
};

export default {
  setAccessToken,
  getAccessToken,
  setUserData,
  getUserData,
  getServices,
  setAllServices,
  getAllServices,
  setJanusRoom,
  clear,
};
