import http from "@/services/http.service";
import { MEMBERS, SHARE_MEMBER, USERS, ROOMS } from "@/constants/apiUrls";
import numberUtility from "@/utils/number.utility";

const members = async (commit, query) => {
  const response = await http.get(MEMBERS, { params: query });

  response.data.data.forEach((item) => {
    item.representative_phone = numberUtility.toUsPhone(
      item.representative_phone
    );
  });
  commit("setMembers", response);
};

const shareMember = async (commit, payload) => {
  await http.post(SHARE_MEMBER, payload);
};

const users = async (commit, query) => {
  const response = await http.get(USERS, { params: query });
  commit("setUsers", response.data);
};

const getRoomList = async (commit, query) => {
  const response = await http.get(ROOMS, { params: query });
  commit("setRoomList", response.data);
};


export default {
  members,
  shareMember,
  users,
  getRoomList,
};
