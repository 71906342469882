import invoiceService from "@/services/invoice.service";

const state = {
  manufacturer: [],
  modelYears: [],
  carline: [],
  pType: [],
  partsList: [],
  customersList: [],
  invoiceList: [],
  invoiceStatistics: [],
  invoiceAssociateUsers: [],
};

const actions = {
  getManufacturer({ commit }, query) {
    return invoiceService.getManufacturer(commit, query);
  },
  getCarline({ commit }, payload) {
    return invoiceService.getCarline(commit, payload);
  },
  getModelYears({ commit }) {
    return invoiceService.getModelYears(commit);
  },
  getPType({ commit }, query) {
    return invoiceService.getPType(commit, query);
  },
  getPartsList({ commit }, payload) {
    return invoiceService.getPartsList(commit, payload);
  },
  getCustomersList({ commit }, payload) {
    return invoiceService.getCustomersList(commit, payload);
  },
  getInvoiceList({ commit }, payload) {
    return invoiceService.getInvoiceList(commit, payload);
  },
  updateInvoice({ commit }, payload) {
    return invoiceService.updateInvoice(commit, payload);
  },
  saveInvoice({ commit }, payload) {
    return invoiceService.saveInvoice(commit, payload);
  },
  getInvoiceStatistics({ commit }, payload) {
    return invoiceService.getInvoiceStatistics(commit, payload);
  },
  sendInvoicePdfMail({ commit }, payload) {
    return invoiceService.sendInvoicePdfMail(commit, payload);
  },
  getInvoiceAssociateUsers({ commit }, payload) {
    return invoiceService.getInvoiceAssociateUsers(commit, payload);
  },
};

const mutations = {
  setManufacturer(state, response) {
    state.manufacturer = response.data;
  },
  setCarline(state, response) {
    state.carline = response.data;
  },
  setModelYears(state, response) {
    state.modelYears = response.data;
  },
  setPType(state, response) {
    state.pType = response.data;
  },
  setPartsList(state, response) {
    state.partsList = response.data;
  },
  setCustomersList(state, response) {
    state.customersList = response.data;
  },
  setInvoiceList(state, response) {
    state.invoiceList = response.data;
  },
  setInvoiceStatistics(state, response) {
    state.invoiceStatistics = response.data;
  },
  setInvoiceAssociateUsers(state, response) {
    state.invoiceAssociateUsers = response.data;
  },
};

const getters = {
  getManufacturer(state) {
    return state.manufacturer;
  },
  getCarline(state) {
    return state.carline;
  },
  getModelYears(state) {
    return state.modelYears;
  },
  getPType(state) {
    return state.pType;
  },
  getPartsList(state) {
    return state.partsList;
  },
  getCustomersList(state) {
    return state.customersList;
  },
  getInvoiceList(state) {
    return state.invoiceList;
  },
  getInvoiceStatistics(state) {
    return state.invoiceStatistics;
  },
  getInvoiceAssociateUsers(state) {
    return state.invoiceAssociateUsers;
  },
};

export const invoice = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
