import http from "@/services/http.service";
import {
  GET_INVOICE_CARLINE,
  GET_INVOICE_MANUFACTURER,
  GET_INVOICE_YEAR,
  GET_INVOICE_P_TYPE,
  GET_INVOICE_PARTS,
  GET_INVOICE_CUSTOMERS,
  GET_INVOICE,
  SAVE_INVOICE,
  GET_INVOICE_STATISTICS,
  UPDATE_INVOICE,
  SEND_INVOICE_PDF,
  GET_ASSOCIATE,
} from "@/constants/apiUrls";
import { ITEMS_PER_PAGE } from "@/constants/common";

const getManufacturer = async (commit, query) => {
  const response = await http.get(GET_INVOICE_MANUFACTURER, { params: query });
  commit("setManufacturer", response.data);
};

const getCarline = async (commit, payload) => {
  const response = await http.post(GET_INVOICE_CARLINE, payload);
  commit("setCarline", response.data);
  commit("setPType", []);
};

const getModelYears = async (commit) => {
  const response = await http.get(GET_INVOICE_YEAR);
  commit("setModelYears", response.data);
  commit("setPType", []);
};

const getPType = async (commit, query) => {
  const response = await http.get(`${GET_INVOICE_P_TYPE}`, { params: query });
  commit("setPType", response.data);
};

const getPartsList = async (commit, payload) => {
  const response = await http.post(GET_INVOICE_PARTS, payload);
  commit("setPartsList", response.data);
};

const getCustomersList = async (commit, payload) => {
  const response = await http.post(GET_INVOICE_CUSTOMERS, payload);
  commit("setCustomersList", response.data);
};

const getInvoiceList = async (commit, payload) => {
  const response = await http.post(GET_INVOICE, payload);
  commit("setInvoiceList", response);
};

const updateInvoice = async (commit, data) => {
  await http.post(UPDATE_INVOICE, data);
  await getInvoiceList(commit, {
    search: null,
    items_per_page: ITEMS_PER_PAGE,
    page: 1
  });
};

const saveInvoice = async (commit, data) => {
  await http.post(SAVE_INVOICE, data);
};

const getInvoiceStatistics = async (commit, payload) => {
  const response = await http.post(GET_INVOICE_STATISTICS, payload);
  commit("setInvoiceStatistics", response);
};

const sendInvoicePdfMail = async (commit, payload) => {
  const response = await http.post(SEND_INVOICE_PDF, payload);
};

const getInvoiceAssociateUsers = async (commit, payload) => {
  const response = await http.post(GET_ASSOCIATE, payload);
  commit("setInvoiceAssociateUsers", response);
};

export default {
  getManufacturer,
  getCarline,
  getModelYears,
  getPType,
  getPartsList,
  getCustomersList,
  getInvoiceList,
  saveInvoice,
  getInvoiceStatistics,
  updateInvoice,
  sendInvoicePdfMail,
  getInvoiceAssociateUsers,
};
